import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Form from './Form';
import LabelledCheckbox from './LabelledCheckbox';

import { countries, states } from '../geo';

import { createAddAddress, createUpdateAddress, createFinalizeUpdateAddress } from '../actions/address';
import { closePopup } from '../actions/popup';
import { updateOrderAddress } from '../actions/order';
import { oauth } from '../utils';

import { BASE_ZINDEX } from '../popup-factory';
import config from '../config';

class AddAddressPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      address_country: 'United States',
      address_state: '',
      invalid_address: false,
      suggestion: props.suggestion,
      errors: null
    };

    _.bindAll(this, ['onFormRef', 'handleFieldChange', 'onAddAddress', 'onEditAddress', 'ignore']);
  }

  componentDidMount() {
    const { edit_address } = this.props;

    if(edit_address) {
      this.setState({ ...edit_address });
    }
  }

  onFormRef(form) {
    this._form = form;
  }

  handleFieldChange(value, field) {
    this.setState({ [field]: value, suggestion: { ...this.state.suggestion, [field]: null }, errors: { ...this.state.errors, [field]: null } });
  }

  onAddAddress(values, errors) {
    if (!_.every(errors, (error) => error === null)) {
      return;
    }
    if(this.props.shop_address_type) {
      values.address_type = this.props.shop_address_type;
    }
    this.props.onAddAddress(values);
    if(this.props.shop_id && this.props.shop_parent_type && this.props.shop_address_type) {
      this.props.onAddShopAddress(values);
    }
  }

  onEditAddress(values, errors) {
    let skip_validation = false;

    if(this.props.psEligible && this.state.split_shipping == 1) {
      skip_validation = true;
    }

    //not validating address_line_1 && city
    let mod_errors = errors;
    mod_errors.address_line_1 = null;
    mod_errors.address_city = null;
    if(!skip_validation) {
      if (!_.every(mod_errors, (error) => error !== error === null ||  _.isEmpty(error))) {
        return;
      }

      const _this = this;
      if(['United States', 'United States of America', 'US', 'USA'].indexOf(values['address_country']) !== -1) {
        this.validateAddress(values['address_state'], values['address_postal']).then(({ json }) => {
          if(json.result === true) {
            _this.updateAddress(values);
          }else if(json.result.address_city && json.result.address_state) {
            const base_states = _.get(states, this.state.address_country, []);
            let found_state = base_states.filter(s => s.name.toLowerCase() === values['address_state'].toLowerCase() || s.abbr.toLowerCase() === values['address_state'].toLowerCase());

            _this.setState({
              suggestion: { address_city: json.result.address_city, address_state: json.result.address_state },
              errors: {
                /* address_city: values['address_city'] !== json.result.address_city ?
                  {
                    message: <div>
                      <span>{`City should be ${json.result.address_city}`}</span>
                    </div>
                  }
                : null, */
                address_state: (found_state.length && json.result.address_state !== found_state[0].name) || !found_state.length ?
                  {
                    message: <div>
                      <span>{`State should be ${json.result.address_state}`}</span>
                    </div>
                  }
                : null
              }
           }, function() {
             if(!this.state.errors.address_state) {
              _this.updateAddress(values);
             }
           });
          }else {
            _this.setState({
              suggestion: { address_postal: json.result.address_postal },
              errors: {
                address_postal: {
                  message: <div>
                    <span>{json.result.address_postal}</span>
                  </div>
                }
              }
            });
          }
        });
      }else{
        this.updateAddress(values);
      }
    }else{
      this.updateAddress(values);
    }
  }

  validateAddress(address_state, address_postal) {
    return oauth('POST', 'address', {
      action: 'validate-address',
      address_state,
      address_postal
    });
  }

  updateAddress(values) {
    const { onClosePopup, onCreateUpdateAddress, edit_address, onCreateFinalizeUpdateAddress } = this.props;

    _.forEach(values, function(value, key) {
      if(value !== edit_address[key]) {
        onCreateUpdateAddress(edit_address.address_id, key, edit_address[key], value);
      }
    });

    //trying to not ask for validation again in the same session
    onCreateFinalizeUpdateAddress(edit_address.address_id, 'split_shipping', 0)(1);

    onClosePopup();
  }

  ignore(address_id) {
    const { onCreateFinalizeUpdateAddress, onClosePopup } = this.props;

    onCreateFinalizeUpdateAddress(address_id, 'split_shipping', 0)(1);
    onClosePopup();
  }

  render() {
    const {
      onClosePopup,
      index,
      edit_address,
      psEligible
    } = this.props;

    const country_options = countries.map(c => ({key: c, value: c}));
    const base_states = _.get(states, this.state.address_country, []);
    const state_options = [{key: '', value: this.state.address_country === 'Canada' ? 'Select a Province...' : 'Select a State...'}].concat(base_states.map(s => ({key: s.abbr, value: s.name})));

    let validate = (value, p, error) => {
      if(this.state.suggestion && this.state.suggestion[p.field]) {
        let errorMessage = this.state.suggestion[p.field];

        if(p.field === 'address_city' && this.state.suggestion[p.field] !== value && this.state.suggestion[p.field] !== 'City is required') {
          errorMessage = 'City should be ' + errorMessage;
        }

        if(p.field === 'address_state') {
          let found_state = state_options.filter(s => s.value.toLowerCase() === value.toLowerCase() || s.key.toLowerCase() === value.toLowerCase());
          if(found_state.length) {
            this.setState({ address_state: found_state[0].key });
          }
          if((found_state.length && this.state.suggestion[p.field] !== found_state[0].value) || !found_state.length) {
            errorMessage = 'State should be ' + errorMessage;
          }else{
            return {};
          }
        }

        return {
          ...(error || {}),
          message: <div>
            <span>{errorMessage}</span>
          </div>,
        };
      }

      return error;
    };

    return (
      <div id="replace-header-modal" className="reveal" style={{display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">
              {edit_address && psEligible ? 'Verify Address' : edit_address ? 'Edit Address' : 'Add Address'}
            </h3>
            <a className="alert button" style={{ position: 'fixed', right: '6rem', top: '1rem' }}
              onClick={e => { e.preventDefault(); edit_address && psEligible ? this.ignore(edit_address.address_id) : onClosePopup(); }}>{edit_address && psEligible ? 'Ignore' : 'Cancel'}</a>
            <a className="button" style={{position: 'fixed', right: '1rem', top: '1rem' }} onClick={(e) => {e.preventDefault(); this._form && this._form.submit();}}>{edit_address ? 'Update' : 'Save'}</a>
          </div>
        </div>
        <Form className="row popup-content form"
          ref={this.onFormRef}
          object={{
            parent_id: edit_address ? edit_address.parent_id : this.props.address.parent_id,
            parent_type: edit_address ? edit_address.parent_type : this.props.address.parent_type
          }}
          onFieldChange={this.handleFieldChange}
          onSubmit={edit_address ? this.onEditAddress : this.onAddAddress}
          initialValidate={edit_address && psEligible ? true : false}
          errors={this.state.errors}
        >
          {edit_address && psEligible ? <p style={{ fontSize: '18px', fontStyle: 'italic' }} className="row small-12 columns">PromoStandards requires accurate shipping information</p> : null}
          {this.state.invalid_address ? <div className="row" style={{ backgroundColor: '#FFF3D9', padding: '10px', borderRadius: '10px', margin: '0 0 20px 15px', width: '90%' }}>Invalid address... please check your address and save again</div> : null}
          {!psEligible ? <Form.TextInput label="Address Name" field="address_name" value={this.state.address_name} required /> : null}
          <Form.TextInput label="Company Name" field="address_company" value={this.state.address_company} required />
          <Form.AddressAutocompleteInput
            label="Street Address"
            field="address_line_1"
            containerStyles={{ marginBottom: '12px !important' }}
            value={this.state.address_line_1}
            country={config.addressAutocompleteCountries}
            validate={validate}
            required
            onChange={(v) => {
              this.setState(v.parsed_address);
            }}
            onInputChange={(v) => {
              this.setState({ address_line_1: v });
            }}
          />
          <Form.TextInput label="Suite, Apt etc." field="address_line_2" value={this.state.address_line_2} />
          <Form.TextInput label="Optional 1" field="address_line_3" value={this.state.address_line_3} />
          <Form.TextInput label="Optional 2" field="address_line_4" value={this.state.address_line_4} />
          <Form.TextInput label="City" field="address_city" value={this.state.address_city} validate={validate} required />
          <Form.Select label="Country" field="address_country"
            withMarginBottom
            options={country_options} value={this.state.address_country}
            onChange={v => this.handleFieldChange('', 'address_state')}
            required
          />
          {base_states.length ?
            <Form.Select label="State/Province" field="address_state"
              withMarginBottom
              options={state_options} value={this.state.address_state}
              validate={validate} required
            /> :
            <Form.TextInput label="State/Province" field="address_state" value={this.state.address_state} required />
          }
          <Form.TextInput label="Postal/Zip Code" field="address_postal" value={this.state.address_postal} validate={validate} required />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let edit_address = ownProps.address_id ? state.entities.addresses[ownProps.address_id] : null;

  return {
    edit_address
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onAddShopAddress: address => dispatch(createAddAddress({ ...address, parent_id: ownProps.shop_id, parent_type: ownProps.shop_parent_type, address_type: ownProps.shop_address_type })),
    onAddAddress: address => dispatch(createAddAddress(address)).then(action => {
      dispatch(closePopup());
      if (ownProps.order_id) {
        return dispatch(updateOrderAddress('shipping_address_id' === ownProps.field ? 'shipping' : 'billing')(ownProps.order_id, ownProps.previous_value, action.payload.address.address_id));
      }
    }),
    onClosePopup: (index) => {
      dispatch(closePopup(index));
    },
    onCreateUpdateAddress: (address_id, field, previous_value, value) => {
      dispatch(createUpdateAddress(address_id, field, previous_value, value));
    },
    onCreateFinalizeUpdateAddress: (address_id, field, previous_value) => value => {
      dispatch(createFinalizeUpdateAddress(address_id, field, previous_value, value));
    }
  };
};

const ConnectedAddAddressPopup = connect(mapStateToProps, mapDispatchToProps)(AddAddressPopup);
export default ConnectedAddAddressPopup;

